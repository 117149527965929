import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useReducer, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Container,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { getError, getStatus, maxitemsPerPage } from "../../utils";
import { Store } from "../../Store";
import Tab from "../../components/Tabs/Tabs";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Pagination from "../../components/Pagination/Pagination";
import { SiMicrosoftexcel } from "react-icons/si";

import {
  BsPlusCircle,
  BsTrash,
  BsPencilSquare,
  BsFileEarmarkRichtext,
} from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import _ticketOrder from "../../services/ticket-order.service";
import "./VentasTrucoNew.css";
import { CiFileOn } from "react-icons/ci";
import { eventId } from "../../utils";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";


const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        tickets: action.payload,
        loading: false,
        itemQuantity: action.payload.length,
      };
      case "FETCH_EVENTOS_REQUEST":
        return { ...state, loading: true };
      case "FETCH_EVENTOS_SUCCESS":
        return {
          ...state,
          eventos: action.payload,
          loading: false,
          // itemQuantity: action.payload.length,
        };
    case "APPROVE_PAYMENT_SUCCESS":
      return {
        ...state,
        tickets: action.payload,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

function VentasTrucoNew() {
  const [{ loading, error, tickets, successDelete, itemQuantity, eventos }, dispatch] =
    useReducer(reducer, {
      tickets: [],
      eventos: [],
      loading: true,
      error: "",
      itemQuantity: 0,
    });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [loadingAction, setLoadingAction] = useState(false);
 
  //MODAL
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  //TABS
  const statuses = [
		{ label: 'Todos', value: 'All' },
		{ label: 'Pendientes', value: 'pending' },
		{ label: 'Pago', value: 'approved' },
	];

  	// Tab Filtering
	const [activeTab, setActiveTab] = useState('All');
	const [awaitingConfirmationCount, setAwaitingConfirmationCount] = useState(0);

  const filterOrdersByStatus = (status) => {
		if (status === 'All') {
			return tickets;
		}
		return tickets.filter(ticket => ticket.ticket?.status === status);
	};

  const filteredOrders = filterOrdersByStatus(activeTab);

   //pagination
   const [currentPage, setCurrentPage] = useState(1);
   const indexOfLastPost = currentPage * maxitemsPerPage;
   const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
   let currentPosts = filteredOrders.slice(indexOfFirstPost, indexOfLastPost);
 
   //change page
   const onPageChange = (pageNumber) => {
     setCurrentPage(pageNumber);
     currentPosts = filteredOrders.slice(indexOfFirstPost, indexOfLastPost);
 
   };

   useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_EVENTOS_REQUEST" });
        const { data } = await axios.get(`/api/events`);
        dispatch({ type: "FETCH_EVENTOS_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_EVENTOS_FAIL", payload: getError(err) });
      }
    };
    fetchData();
  },[]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       dispatch({ type: "FETCH_REQUEST" });
  //       const { data } = await axios.get(
  //         `/api/ticketOrders/truco/ctm/${eventId}`,
  //         {
  //           headers: { Authorization: `Bearer ${userInfo.token}` },
  //         }
  //       );
  //       const count = data.filter(ticket => ticket.ticket?.status === 'pending').length;
	// 			setAwaitingConfirmationCount(count);
       
  //       dispatch({ type: "FETCH_SUCCESS", payload: data });
  //     } catch (err) {
  //       dispatch({ type: "FETCH_FAIL", payload: getError(err) });
  //     }
  //   };
  //   if (successDelete) {
  //     dispatch({ type: "DELETE_RESET" });
  //   } else {
  //     fetchData();
      
  //   }
  // }, [successDelete]);

  // // useLayoutEffect(() => {
  // //  setActiveTab('All')
  // //   changeTab(activeTab)
  // // }, [activeTab])


  async function handleEventSelection(eventId){
    setSelectedEvent(eventId);
    try {
      dispatch({ type: "FETCH_REQUEST" });
      const { data } = await axios.get(
        `/api/ticketOrders/truco/ctm/${eventId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      const count = data.filter(ticket => ticket.ticket?.status === 'pending').length;
      setAwaitingConfirmationCount(count);
     
      dispatch({ type: "FETCH_SUCCESS", payload: data });
    } catch (err) {
      dispatch({ type: "FETCH_FAIL", payload: getError(err) });
    } 
  }

  async function changeTab(tabValue){
    setActiveTab(tabValue)
  }

  async function verComprobante(proofOfPayment) {
    if (proofOfPayment) {
      window.open(proofOfPayment.fileLink, '_blank', 'noopener,noreferrer');
    }
  }
  async function deleteTicketHandler() {
    try {
      dispatch({ type: "DELETE_REQUEST" });
      await axios.delete(`/api/ticketOrders/${ticketToDelete._id}`, {
        headers: {
          Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
        },
      });
      dispatch({ type: "DELETE_SUCCESS" });
      handleClose();
      toast.success("Ticket Eliminado");
    } catch (error) {
      dispatch({ type: "DELETE_FAIL" });
      if (getStatus(error) === 401) {
        ctxDispatch({ type: "USER_SIGNOUT" });
        navigate("/signin");
        toast.error("Sesion expirada. Vuelve a ingresar.");
      } else {
        console.error(error);
        toast.error(getError(error));
      }
    }
  }

  async function handleDelete(ticket) {
    setTicketToDelete(ticket);
    handleShow();
  }

  async function editUserHandler(admin) {
    navigate(`/AdminScreen/editAdmin/${admin._id}`);
  }
  async function approveTicket(ticket) {
    setLoadingAction(true);
    try {
      await _ticketOrder.approveOrder(ticket._id);
      await _ticketOrder.sendConfirmationEmail(ticket._id);
      const ticketIndex = tickets.findIndex( t => t._id === ticket._id);
      const updatedTickets = [...tickets];
      updatedTickets[ticketIndex].ticket.status = 'approved';
      dispatch({type: "APPROVE_PAYMENT_SUCCESS",payload: updatedTickets});
      toast.success("Ticket aprobado");
    } catch (ex) {
      console.error(ex);
      toast.error("No se pudo aprobar el ticket");
    } finally {
      setLoadingAction(false);
    }
  }

  async function downloadExcel() {
    if(!eventId) return;
    try {
      dispatch({ type: "DOWNLOAD_REQUEST" });
      const { data } = await axios.get(`/api/ticketOrders/exportExcel/ctm/${selectedEvent}`, {
        responseType: "arraybuffer",
        headers: { authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: "DOWNLOAD_SUCCESS" });
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `NumerosVendidos.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: "DOWNLOAD_FAIL" });
    }
  }

  async function detailsUserHandler(admin) {
    navigate(`/AdminScreen/admin/${admin._id}`);
  }

  async function newUserHandler() {
    navigate("/AdminScreen/newAdmin");
  }

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>Seguro desea eliminar el ticket?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={deleteTicketHandler}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <Helmet>
        <title>Lista de Tickets</title>
      </Helmet>

      <div className="container admin-con">
        {loadingAction && <LoadingOverlay/>}
        <div className="row adminProdBtnRow align-items-center">
          {/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
          {/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
        </div>
        <div>
          <Container fluid className="m-3 mt-5">
            <h1 className="text-center">Lista de Tickets</h1>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <div className="form-group-ticket">
                <label htmlFor="eventSeleciton">Evento</label>
                <Form.Select
                  id="eventSeleciton"
                  value={selectedEvent}
                  onChange={(e) => handleEventSelection(e.target.value)}
                >
                  <option value="">-</option>
                  {eventos.map((evento) => {
                    return <option value={evento._id}>{evento.nombre}</option>;
                  })}
                </Form.Select>
              </div>
              	<div className="tabs-container">
						{statuses.map(status => (
							<Tab
								key={status.value}
								status={status.label}
								active={activeTab === status.value}
								onClick={() => changeTab(status.value)}
								count={status.value === 'pending' ? awaitingConfirmationCount : 0}

							/>
						))}
             <Button
                      className="btn btn-dark m-1 fixed-right"
                      value="Exportar"
                      onClick={() => downloadExcel()}
                    >
                <span>
                  <SiMicrosoftexcel /> Exportar Excel
                </span>
              </Button>            
					</div>
              {/* <Button
                className="btn btn-dark m-1 fixed-right"
                value="Crear Usuario"
                onClick={() => newUserHandler()}
              >
                <span>
                  <BsPlusCircle /> Crear Administrador
                </span>
              </Button> */}
            </div>
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <>
              <Table responsive className="mx-2">
                <ListGroup>
                  <ListGroupItem>
                    <Row className="align-items-center table-order">
                      {/* <Col md={2}>
                    <span>ID</span>
                  </Col> */}
                      <Col as={"th"} md={1}>
                        <span>ID</span>
                      </Col>
                      <Col as={"th"} md={1}>
                        <span>Jugador 1</span>
                      </Col>
                      <Col as={"th"} md={1}>
                        <span>Jugador 2</span>
                      </Col>
                      <Col as={"th"} md={2}>
                        <span>e-mail</span>
                      </Col>
                      <Col as={"th"} md={1}>
                        Telefono
                      </Col>
                      <Col  className="text-break" as={"th"} md={2}>
                        Entrada
                      </Col>
                      <Col as={"th"} md={1}>
                        Estado
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
                <ListGroup>
                  {currentPosts.map((ticket) => (
                    <ListGroupItem>
                      <Row
                        className="align-items-center table-order"
                        id="data"
                        key={ticket._id}
                      >
                        <Col md={1} className="text-break">{ticket.ticket.serialNumber}</Col>
                        <Col md={1}>{ticket.player1}</Col>
                        <Col md={1}>{ticket.player2}</Col>
                        <Col md={2}>{ticket.email}</Col>
                        <Col md={1} className="text-break">
                          {ticket.phone}
                        </Col>
                        <Col md={2} className="text-break">
                          {ticket.ticket.ticketCategory.section.name}
                        </Col>
                        <Col md={1}>
                          {ticket.ticket?.status === 'approved' ? "Pago" : "Pendiente"}
                        </Col>
                        <Col md={2} className="px-1 d-flex justify-content-end">
{ ticket.paymentMethod.toLowerCase() === 'transferencia' &&
                          <OverlayTrigger
                            key="proof-of-payment"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Ver comprobante</Tooltip>
                            }
                          >
                            <Button
                              md={3}
                              className="mx-1"
                              variant="outline-secondary"
                              disabled = {!ticket.proofOfPayment}
                              onClick={() => verComprobante(ticket.proofOfPayment)}
                            >
                              <CiFileOn />
                            </Button>
                          </OverlayTrigger>}

                          {ticket.ticket.status !== 'approved' && <OverlayTrigger
                            key="approve-sale"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Aprobar venta</Tooltip>
                            }
                          >
                            <Button
                              md={3}
                              variant="outline-secondary"
                              className="mx-1 approve-ticket-btn"
                              onClick={() => approveTicket(ticket)}
                            >
                              <TiTick />
                            </Button>
                          </OverlayTrigger>}
                          <OverlayTrigger
                            key="delete"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Eliminar</Tooltip>
                            }
                          >
                            <Button
                              md={3}
                              variant="outline-danger"
                              className="mx-1"
                              //className="btn btn-outline-danger"
                              onClick={() => handleDelete(ticket)}
                            >
                              <BsTrash />
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Table>
              <Pagination
          className="pagination-bar"
          totalCount={itemQuantity}
          onPageChange={onPageChange}
          currentPage={currentPage}
          pageSize={maxitemsPerPage}
        >
        </Pagination>
              </>
            )}
          </Container>
        </div>
  
      </div>
    </div>
  );
}

export default VentasTrucoNew;
